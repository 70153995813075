import React, { useState, useEffect, useRef } from "react"

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import { Link, useLocation } from "react-router-dom";

import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

import Menu from "./Menu/Menu";
import Certificate from './Certificate';
import Scope from "./Scope";
import ScopesStatus from "./ScopesStatus";

import VisorStyle from '../css/Visor.module.css'
import ReportStyle from '../css/Report.module.css'
import ScopeStyle from '../css/Scope.module.css'
import ScopesStatusStyle from '../css/ScopesStatus.module.css'
import CertificateStyle from '../css/Certificate.module.css'

import logo from '../images/logo-cnc.png';

import { API_DEFAULT } from "../services/settings";


function Visor(props) {

  const { setAccessToken, setRefreshToken, setAuth, accessToken, refreshToken, showUser, setShowLoader, setShowAlert, setMessage, setSeverity } = props;

  const [paths, setPaths] = props.paths;
  const location = useLocation();

  const [pbiEmbed, setPbiEmbed] = useState({})
  const [dataViewers, setDataViewers] = useState([])
  const [index, setIndex] = useState()
  const selectedViewer = dataViewers[index]

  const [dataReports, setDataReports] = useState([])
  const [saveReports, setSaveReports] = useState([])
  const [showIconReport, setShowIconReport] = useState(false)
  const [groupDays, setGroupDays] = useState([])
  const [groupDaysCNC, setGroupDaysCNC] = useState([])
  const [organizationNames, setOrganizationNames] = useState([])
  const [click, setClick] = useState(true)

  const [viewersNewUniverse, setViewersNewUniverse] = useState([])
  const [viewersModelOne, setViewersModelOne] = useState([])
  const [viewersClaroUniverse, setViewersClaroUniverse] = useState([])
  const [viewersFiveMinutes, setViewersFiveMinutes] = useState([])
  const [othersViewers, setOthersViewers] = useState([])

  const monthsAvailable = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

  const scopeStatusRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (scopeStatusRef.current) {
        const data = await scopeStatusRef.current.getScopesProcess(accessToken);
      }
    };

    fetchData();
  }, [accessToken]);

  const handleButtonClick = async () => {
    if (scopeStatusRef.current) {
      const data = await scopeStatusRef.current.getScopesProcess(accessToken);
    }
  };

  const logOut = async (access = accessToken) => {
    setShowLoader(true);

    const res = await fetch(
      `${API_DEFAULT}/usuarios/logout`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
      body: JSON.stringify({
        'all': false,
        'refresh': refreshToken
      })
    });

    if (res.ok) {
      setShowLoader(false);
      signOff();
    }
    else {
      res.json().then(async res => {
        if (res['code'] === 'token_not_valid') {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            localStorage.setItem('access', newAccess);
            logOut(newAccess);
          }
        }
        else {
          setShowLoader(false);
          setMessage("Ocurrio un error, intente de nuevo.");
          setSeverity("danger");
          setTimeout(() => { setShowAlert(true) }, 0);
        }
      });
    }
  }

  // Función para cerrar sesión
  const signOff = () => {
    setAccessToken("");
    setRefreshToken("");
    setAuth(false);
    setPaths(["/inicio-sesion", "/visor-rpd"]);
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("auth")
    localStorage.removeItem("customPaths")
    localStorage.removeItem("user")
    localStorage.removeItem("selectedButton")
    localStorage.removeItem("selectedMenuButton")
    setShowLoader(false);
  }

  const claroUsersOTTs = ['virginia.sanchez', 'gloria.navarrera.ext', 'cindy.cetina']
  const cncUsersOTTs = ['falarcon', 'admin-ratings', 'jtorres', 'ftrujillo']

  // Función que obtiene un objeto[] con la información de todos los visores que puede ver el usuario.
  const getViewers = async (access) => {
    const res = await fetch(
      `${API_DEFAULT}/usuarios/visores`, {
      headers: {
        "Authorization": "Bearer " + access
      }
    }
    )

    res.json().then(
      async data => {
        //Ordenar el objeto   
        if (data['code'] === 'token_not_valid') {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            localStorage.setItem('access', newAccess);
          }
        }
        else {
          let sortedData = data.sort((a, b) => {
            // Definir el orden de prioridad para tipo_visor -> 3 y 4 son nuevo universo, 0 y 1 son universo Claro, 2 son 5 minutos y 9 son otros
            const tipoVisorPriority = [5, 6, 3, 4, 0, 1, 2, 9];

            // Comparar tipo_visor en base a la prioridad especificada
            const tipoVisorOrderA = tipoVisorPriority.indexOf(a.tipo_visor);
            const tipoVisorOrderB = tipoVisorPriority.indexOf(b.tipo_visor);

            if (tipoVisorOrderA < tipoVisorOrderB) {
              return -1;
            }
            if (tipoVisorOrderA > tipoVisorOrderB) {
              return 1;
            }

            // Si tipo_visor es el mismo, ordenar por año de manera descendente
            if (a.anio > b.anio) {
              return -1;
            }
            if (a.anio < b.anio) {
              return 1;
            }

            // Si año es el mismo, ordenar por mes de manera descendente
            if (a.mes > b.mes) {
              return -1;
            }
            if (a.mes < b.mes) {
              return 1;
            }

            // Si mes es el mismo, ordenar por label de manera ascendente
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }

            return 0;
          });


          const visoresNewUniverse = sortedData.filter(visor => visor.tipo_visor === 5 || visor.tipo_visor === 6);
          setViewersNewUniverse(visoresNewUniverse)

          const visoresModeloUno = sortedData.filter(visor => visor.tipo_visor === 3 || visor.tipo_visor === 4);
          setViewersModelOne(visoresModeloUno)

          const visoresUniversoClaro = sortedData.filter(visor => visor.tipo_visor === 0 || visor.tipo_visor === 1);
          setViewersClaroUniverse(visoresUniversoClaro)

          const visoresCincoMinutos = sortedData.filter(visor => visor.tipo_franja === 1 && visor.tipo_visor !== 9);
          setViewersFiveMinutes(visoresCincoMinutos)

          const otrosVisores = sortedData.filter(visor => visor.tipo_visor === 9);
          setOthersViewers(otrosVisores)


          const customPaths = ["/inicio-sesion", "/visor-rpd", ...sortedData.map(
            data => `/visor-rpd/${data.label.toLowerCase().replaceAll(" ", "-")}`
          )];

          const usersWithSpecialAccess = claroUsersOTTs.concat(cncUsersOTTs)

          if (usersWithSpecialAccess.includes(showUser) === true) {
            setPaths(customPaths);
            localStorage.setItem('customPaths', customPaths);

            setDataViewers(sortedData)
          }
          else {
            const filteredPaths = customPaths.filter((item) => item !== '/visor-rpd/febrero-2023-otts')
            setPaths(filteredPaths);
            localStorage.setItem('customPaths', customPaths);

            const removeOTTS = sortedData.filter((item) => item.label !== 'Febrero 2023 OTTs')
            setDataViewers(removeOTTS)
          }

          setIndex(0)
          // toggleButton(false)

        }
      }
    )
  }

  const newUniverseViewersLength = viewersNewUniverse.filter(visor => visor.tipo_visor === 5).length - 1
  const tabMenu =
    <div className={VisorStyle["tab-menu"]}>
      <Link to={paths[index + 1 - newUniverseViewersLength]} onClick={() => changeViewer(index - 1 - newUniverseViewersLength)} className={VisorStyle["tab-button"]}>
        <ion-icon name="home-outline"></ion-icon>
        <span>HOGARES</span>
      </Link>
      <Link to={paths[index + 3 + newUniverseViewersLength]} onClick={() => changeViewer(index + 1 + newUniverseViewersLength)} className={VisorStyle["tab-button"]}>
        <ion-icon name="accessibility-outline"></ion-icon>
        <span>PERSONAS</span>
      </Link>
    </div>
  
  let tabMenuModelOne;
  try {
    const modelOneViewersLength = viewersModelOne.filter(visor => visor.tipo_visor === 4).length - 1
    tabMenuModelOne =
      <div className={VisorStyle["tab-menu"]}>
        <Link to={paths[index + 1 - modelOneViewersLength]} onClick={() => changeViewer(index - 1 - modelOneViewersLength)} className={VisorStyle["tab-button"]}>
          <ion-icon name="home-outline"></ion-icon>
          <span>HOGARES</span>
        </Link>
        <Link to={paths[index + 3 + modelOneViewersLength]} onClick={() => changeViewer(index + 1 + modelOneViewersLength)} className={VisorStyle["tab-button"]}>
          <ion-icon name="accessibility-outline"></ion-icon>
          <span>PERSONAS</span>
        </Link>
      </div>
  } catch (e) {
  }

  let tabMenuClaroUniverse;
  try {
    const claroUniverseViewersLength = viewersClaroUniverse.filter(visor => visor.tipo_visor === 1).length - 1
    tabMenuClaroUniverse =
      <div className={VisorStyle["tab-menu"]}>
        <Link to={paths[index + 1 - claroUniverseViewersLength]} onClick={() => changeViewer(index - 1 - claroUniverseViewersLength)} className={VisorStyle["tab-button"]}>
          <ion-icon name="home-outline"></ion-icon>
          <span>HOGARES</span>
        </Link>
        <Link to={paths[index + 3 + claroUniverseViewersLength]} onClick={() => changeViewer(index + 1 + claroUniverseViewersLength)} className={VisorStyle["tab-button"]}>
          <ion-icon name="accessibility-outline"></ion-icon>
          <span>PERSONAS</span>
        </Link>
      </div>
  } catch (e) {
  }

  let tabMenuTresCanales;
  let condition = (value) => value.split('/')[2] === "Datos 3 Canales Personas".toLowerCase().split(" ").join("-");;
  try {
    if (selectedViewer.report_id == "08e8ed51-318e-42f6-80a4-056649fcb726" || selectedViewer.report_id == "f0434e95-e7b7-4c46-983f-eb37768a8e16") {
      condition = (value) => value.split('/')[2] === "Agosto 2024 Reescal Esc 1 Personas".toLowerCase().split(" ").join("-");
    }

    if (selectedViewer.report_id == "20954d5d-8b64-4293-92f4-b25ad130c7cd" || selectedViewer.report_id == "3f778b7f-4774-4e4c-8188-46bd4c6e3e7f") {
      condition = (value) => value.split('/')[2] === "Agosto 2024 Reescal Esc 2 Personas".toLowerCase().split(" ").join("-");
    }

    if (selectedViewer.report_id == "6f62150a-85cb-4727-b0f0-0d602a942513" || selectedViewer.report_id == "5f0d1c2e-46b6-4ed6-b787-60cbbd8f385d") {
      condition = (value) => value.split('/')[2] === "Agosto 2024 Reescal Esc 3 OLD Personas".toLowerCase().split(" ").join("-");
    }

    if (selectedViewer.report_id == "a1a34772-e721-4283-aea2-e3aeeb0fa1b5" || selectedViewer.report_id == "23aea5be-30e8-421f-98c6-a580f587a278") {
      condition = (value) => value.split('/')[2] === "Agosto 2024 Reescal Esc 3 Personas".toLowerCase().split(" ").join("-");
    }

    if (selectedViewer.report_id == "36a0d98f-284f-4c75-af39-dd6b385b3514" || selectedViewer.report_id == "7c200ef6-613c-453e-a94f-a2b64aa96c61") {
      condition = (value) => value.split('/')[2] === "Enero 2024 Reescal Personas".toLowerCase().split(" ").join("-");
    }

    if (selectedViewer.report_id == "8110c992-704a-4d2b-8d0a-e137c51cebaa" || selectedViewer.report_id == "a3fa9cf5-63b2-44c9-b52d-30be5f78a8e4") {
      condition = (value) => value.split('/')[2] === "Octubre 2024 Reescal Personas".toLowerCase().split(" ").join("-");
    }

    if (selectedViewer.report_id == "dc0602fb-8f69-432f-bdad-d8d16c56427d" || selectedViewer.report_id == "991e7686-2c29-4be6-98d9-7b7167198e2d") {
      condition = (value) => value.split('/')[2] === "Nov 2024 Nuevos Ponderadores Personas".toLowerCase().split(" ").join("-");
    }

    if (selectedViewer.report_id == "cc119df5-e6d5-47cc-9cf3-79df38f7e1a1" || selectedViewer.report_id == "9bc1862f-f4dc-40cb-95ed-e2f3b4401110") {
      condition = (value) => value.split('/')[2] === "Dic 2024 Nuevos Ponderadores Personas".toLowerCase().split(" ").join("-");
    }

    if (selectedViewer.report_id == "d3729eae-f43a-46fa-bf1c-af4cfe23ce86" || selectedViewer.report_id == "99573157-0e26-4055-b6c1-4b6a6dbdcb7e") {
      condition = (value) => value.split('/')[2] === "Nov 2024 Ajuste Share Personas".toLowerCase().split(" ").join("-");
    }

    const index = paths.slice(2).findIndex(condition);

    tabMenuTresCanales =
      <div className={VisorStyle["tab-menu"]}>
        <Link to={paths[index + 1]} onClick={() => changeViewer(index - 1)} className={VisorStyle["tab-button"]}>
          <ion-icon name="home-outline"></ion-icon>
          <span>HOGARES</span>
        </Link>
        <Link to={paths[index + 2]} onClick={() => changeViewer(index)} className={VisorStyle["tab-button"]}>
          <ion-icon name="accessibility-outline"></ion-icon>
          <span>PERSONAS</span>
        </Link>
      </div>
  } catch (e) {
  }

  // Mostrar el menú de hogares y personas para los visores correspondientes
  try {
    const viewerMenu = document.getElementsByClassName(VisorStyle["tab-menu"])[0]
    const mainContainer = document.getElementsByClassName(VisorStyle["main-container"])[0]

    const viewersThreeChannels = ["5c77e9ba-52d8-495d-8467-4bf9c3817c30", "db074514-9fbe-4879-94ec-855448b4a8f3"]
    const viewersReescalEscUno = ["08e8ed51-318e-42f6-80a4-056649fcb726", "f0434e95-e7b7-4c46-983f-eb37768a8e16"]
    const viewersReescalEscDos = ["20954d5d-8b64-4293-92f4-b25ad130c7cd", "3f778b7f-4774-4e4c-8188-46bd4c6e3e7f"]
    const viewersReescalEscTresOLD = ["6f62150a-85cb-4727-b0f0-0d602a942513", "5f0d1c2e-46b6-4ed6-b787-60cbbd8f385d"]
    const viewersReescalEscTres = ["a1a34772-e721-4283-aea2-e3aeeb0fa1b5", "23aea5be-30e8-421f-98c6-a580f587a278"]
    const viewersReescalEnero = ["36a0d98f-284f-4c75-af39-dd6b385b3514", "7c200ef6-613c-453e-a94f-a2b64aa96c61"]
    const viewersReescalOctubre = ["8110c992-704a-4d2b-8d0a-e137c51cebaa", "a3fa9cf5-63b2-44c9-b52d-30be5f78a8e4"]
    const viewersNovNuevosPonderadores = ["dc0602fb-8f69-432f-bdad-d8d16c56427d", "991e7686-2c29-4be6-98d9-7b7167198e2d"]
    const viewersDicNuevosPonderadores = ["cc119df5-e6d5-47cc-9cf3-79df38f7e1a1", "9bc1862f-f4dc-40cb-95ed-e2f3b4401110"]
    const viewersAjusteShare = ["d3729eae-f43a-46fa-bf1c-af4cfe23ce86", "99573157-0e26-4055-b6c1-4b6a6dbdcb7e"]

    const especialViewers = viewersThreeChannels.concat(viewersReescalEscUno).concat(viewersReescalEscDos).concat(viewersReescalEscTresOLD).concat(viewersReescalEscTres).concat(viewersReescalEnero).concat(viewersReescalOctubre).concat(viewersNovNuevosPonderadores).concat(viewersDicNuevosPonderadores).concat(viewersAjusteShare)

    if (selectedViewer.tipo_visor === 2 || selectedViewer.tipo_visor === 9) {
      if (selectedViewer.workspace_id === "fc403559-ce56-4846-9c8b-dc918ff7c60f" && (especialViewers.includes(selectedViewer.report_id))) {
        mainContainer.classList.remove(VisorStyle["show-main-container"])
        viewerMenu.classList.add(VisorStyle["show-tab-menu"])
      } else {
        mainContainer.classList.add(VisorStyle["show-main-container"])
        viewerMenu.classList.remove(VisorStyle["show-tab-menu"])
      }
    }
    else {
      mainContainer.classList.remove(VisorStyle["show-main-container"])
      viewerMenu.classList.add(VisorStyle["show-tab-menu"])
    }
  } catch (error) {
  }

  useEffect(() => {
    if (location.pathname.includes("hogares")) {
      localStorage.setItem('selectedButton', 'hogares');
    } else if (location.pathname.includes("personas")) {
      localStorage.setItem('selectedButton', 'personas')
    }

    const hogaresButton = document.getElementsByClassName(VisorStyle["tab-button"])[0];
    const personasButton = document.getElementsByClassName(VisorStyle["tab-button"])[1];
    if (hogaresButton && personasButton) {
      const changeStyle = (button1, button2) => {
        button1.disabled = false;
        button1.classList.remove(VisorStyle["tab-button-selected"]);
        button2.classList.add(VisorStyle["tab-button-selected"]);
        button2.disabled = true;
      };

      const setSelectedButton = () => {
        const selectedButton = localStorage.getItem('selectedButton') || 'hogares';
        if (selectedButton === 'hogares') {
          changeStyle(personasButton, hogaresButton);
        } else if (selectedButton === 'personas') {
          changeStyle(hogaresButton, personasButton);
        }
      };

      hogaresButton.addEventListener("click", function () {
        changeStyle(personasButton, hogaresButton);
        localStorage.setItem('selectedButton', 'hogares');
      });

      personasButton.addEventListener("click", function () {
        changeStyle(hogaresButton, personasButton);
        localStorage.setItem('selectedButton', 'personas');
      });

      // Establecer el botón seleccionado cuando el componente se monta
      setSelectedButton();
    }
  }, [location.pathname]);


  // const [dataCanalPrograma, setDataCanalPrograma] = useState([])
  // const [unificadosOptions, setUnificadosOptions] = useState(false)

  // // Función que obtiene un objeto[] con la informaciónd de las tuplas canal y programa para reach neto
  // const getCanalPrograma = async (access, unificados = unificadosOptions) => {
  //   setShowLoader(true);
  //   const res = await fetch(
  //     unificados ? `${API_DEFAULT}/usuarios/canal-programa-alter` : `${API_DEFAULT}/usuarios/canal-programa`, {
  //       headers: {
  //         "Authorization": "Bearer " + access
  //       }
  //     }
  //   )

  //   res.json().then(
  //     async data => {
  //       if (data['code'] === 'token_not_valid') {
  //         let newAccess = await getAccessTokenWithRefresh();
  //         if (newAccess) {
  //           setAccessToken(newAccess);
  //           localStorage.setItem('access', newAccess);
  //         }
  //       }
  //       else {
  //         setDataCanalPrograma(data['canal_name'].map((v, i) => {
  //           return({ value: i, label: v})
  //         }));
  //         setShowLoader(false);
  //       }
  //     }
  //   )
  // }


  // Función que obtiene un objeto[] con la información de todos los reportes que puede ver el usuario.
  const getReports = async (access) => {
    const res = await fetch(
      `${API_DEFAULT}/usuarios/reportes`, {
      headers: {
        "Authorization": "Bearer " + access
      }
    }
    )

    res.json().then(
      async data => {
        if (data['code'] === 'token_not_valid') {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            localStorage.setItem('access', newAccess);
          }
        }
        else {
          // Organización sin reportes: Devuelve un array de longitud 0.
          // Organización con reportes: Devuelve un array donde cada posición es un objeto con el detalle del reporte.
          // Organización CNC: Devuelve un objeto donde cada clave es una organización que tiene reportes, y el valor es un array que contiene los reportes de dicha organización.
          if (Array.isArray(data)) {
            data.length === 0 ? setShowIconReport(false) : setShowIconReport(true);
          }
          else if (typeof data === 'object') {
            setShowIconReport(true);
            const orderedKeys = Object.keys(data).sort();

            const orderedData = Object.fromEntries(
              orderedKeys.map(function (clave) {
                return [clave, data[clave]];
              })
            );

            const organizations = Object.keys(orderedData);
            setOrganizationNames(organizations)

            const saveReportsLocale = organizations.map(clave => orderedData[clave]);
            setSaveReports(saveReportsLocale)
          }

          setDataReports(data)
        }
      }
    )
  }

  // Función para ordenar los reportes por fecha y nombre.
  const sortReports = (reports) => {
    return reports.sort((a, b) => {
      const firstDate = new Date(a.fecha_reporte);
      const secondDate = new Date(b.fecha_reporte);

      if (firstDate.getTime() === secondDate.getTime()) {
        const firstDocument = a.documento.split("/").slice(-1)[0];
        const secondDocument = b.documento.split("/").slice(-1)[0];
        return firstDocument.localeCompare(secondDocument);
      }

      return firstDate.getTime() - secondDate.getTime();
    });
  };

  const groupReports = (reports) => {
    const groups = reports.reduce((groups, report) => {
      const year = new Date(report.fecha_reporte + 'T00:00:00').getFullYear();
      if (!groups[year]) {
        groups[year] = [];
      }
      groups[year].push(report);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((year) => {
      return {
        year,
        reports: groups[year]
      };
    });

    const groupedData = []
    groupArrays.map((element) => {
      const groupDaysByMonth = element['reports'].reduce((p, c) => {

        let x = new Date(c.fecha_reporte + 'T00:00:00')
        let m = x.getMonth()
        let y = x.getFullYear()

        let idx = p[0].indexOf(m);

        if (idx > -1) {

          p[1][idx].days.push(({
            fecha_reporte: c.fecha_reporte,
            documento: c.documento
          }));

        } else {

          p[0].push(m);

          let tmp = {
            month: m,
            year: y,
            days: [{
              fecha_reporte: c.fecha_reporte,
              documento: c.documento
            },],
          }

          p[1].push(tmp);

        }

        return p;
      }, [[], []])[1]; // -->  Para el resultado nos interesa solo segundo elemento
      groupedData.push(groupDaysByMonth)
    })
    return groupedData
  }

  let sortedReportsCNC = []
  const sortReportsCNC = () => {
    saveReports.map((element) => {
      const sortedReports = sortReports(element);
      sortedReportsCNC.push(sortedReports)
    })
  }

  let groupedMonthsCNC = []
  const groupReportsByYearAndMonthCNC = () => {
    sortedReportsCNC.map((element) => {
      const groupedData = groupReports(element)
      groupedMonthsCNC.push(groupedData)
    })
    setGroupDaysCNC(groupedMonthsCNC)
  }

  const sortReportsAllOrganizations = () => {
    const sortedReports = sortReports(dataReports)
    setDataReports(sortedReports)
  }

  const groupReportsByYearAndMonthAllOrganizations = () => {
    const groupedData = groupReports(dataReports)
    setGroupDays(groupedData)
  }

  const loadReports = () => {
    if (dataReports.length > 0) {
      sortReportsAllOrganizations();
      groupReportsByYearAndMonthAllOrganizations()
      setTimeout(() => addClick(), 0);
    } else if (Object.keys(dataReports).length > 0) {
      sortReportsCNC()
      groupReportsByYearAndMonthCNC()
      setTimeout(() => addClick(), 0);
    }
  }

  const addClick = () => {
    if (click) {
      const groupMonths = [...document.getElementsByClassName(ReportStyle["layer-month-container"])]
      const groupDays = document.getElementsByClassName(ReportStyle["report-days-container"])
      const monthArrowIcon = document.getElementsByClassName(ReportStyle["month-arrow-icon"])

      const organizationName = [...document.getElementsByClassName(ReportStyle["name-container"])]
      const groupOrganization = [...document.getElementsByClassName(ReportStyle["report-group-container"])]
      const organizationArrowIcon = document.getElementsByClassName(ReportStyle["organization-arrow-icon"])

      if (dataReports.length > 0) {
        groupOrganization.map((group) => {
          group.style.height = 'auto'
        })
      }
      organizationName.map((org, i) => {
        org.addEventListener('click', function () {
          groupOrganization[i].classList.toggle(ReportStyle["active"])
          organizationName[i].classList.toggle(ReportStyle["active"])
          organizationArrowIcon[i].classList.toggle(ReportStyle["icon-rotate"])
        })
      })

      groupMonths.map((group, i) => {
        group.addEventListener('click', function () {
          groupDays[i].classList.toggle(ReportStyle["active"])
          monthArrowIcon[i].classList.toggle(ReportStyle["icon-rotate"])
        })
      })

      setClick(false)

    }
  }

  /*   const selectCheckbox = () => {
      let checkboxMonth = []
      groupDays.map((r) => {
        checkboxMonth.push(document.getElementById(r.month + '' + r.year))
      })
      
      for (let i = 0; i < checkboxMonth.length; i++) {
        if (checkboxMonth[i].type == "checkbox") {
          checkboxMonth[i].checked = true
        }
      }
    }
   
    const toggleCheckBoxGroup = (event) => {
      console.log(event)
      const checkboxGroup = event.nativeEvent.path[2];
      const checkboxMonthInputValue = event.nativeEvent.path[0].checked;
   
      const checkboxDays = [...checkboxGroup.childNodes[1].childNodes];
      checkboxDays.map((day) => { day.childNodes[0].checked = checkboxMonthInputValue })
   
      const reportsDays = [...event.nativeEvent.path[2].childNodes[1].childNodes]
      const statusCheckboxes = reportsDays.map((dayReport) => { return dayReport.childNodes[0].checked })
   
      const checkboxesActive = statusCheckboxes.filter(statusCheckbox => statusCheckbox == new Boolean(true))
   
      if (checkboxesActive.length > 0) {
        toggleButton(true)
      } else {
        toggleButton(false)
      }
    }
   
    const toggleCheckBoxDay = (event) => {
      const reportsDays = [...event.nativeEvent.path[2].childNodes]
      const checkboxMonthInput = event.nativeEvent.path[3].childNodes[0].childNodes[0]
   
      const statusCheckboxes = reportsDays.map((dayReport) => { return dayReport.childNodes[0].checked })
   
      const checkboxesActive = statusCheckboxes.filter(statusCheckbox => statusCheckbox == new Boolean(true))
   
      if (checkboxesActive.length === statusCheckboxes.length) {
        checkboxMonthInput.checked = true
      } else {
        checkboxMonthInput.checked = false
      }
   
      if (checkboxesActive.length > 0) {
        toggleButton(true)
      } else {
        toggleButton(false)
      }
    }
   
    const toggleButton = (state) => {
   
      const btnDownload = document.getElementById('btnDownload')
   
      if (state === true) {
        btnDownload.disabled = false
      } else {
        btnDownload.disabled = true
      }
    } */

  const reports = []
  const paintReports = groupDays.length > 0 ? groupDays.map((element) => {
    reports.push(element.map((r) => {
      return (
        <div className={ReportStyle["report-group-container"]}>

          <div className={ReportStyle["report-month-container"]}>
            <div className={ReportStyle["layer-month-container"]}></div>
            <input type="checkbox" id={r.month + '' + r.year} /* onClick={selectCheckbox} */ name="checkbox"></input>
            <label for={r.month + '' + r.year}> {`${monthsAvailable[r.month]} ${r.year}`} </label>
            <div className={ReportStyle["month-arrow-icon"]} >
              <ion-icon name="chevron-down-outline"></ion-icon>
            </div>
          </div>

          <div className={ReportStyle["report-days-container"]}>
            {r.days.map((d) => {
              return (
                <div className={ReportStyle["report-days-group"]}>
                  <input type="checkbox" id={d.documento.split("/").slice(-1)[0]} value={d.documento} className="input-report" /* onClick={toggleCheckBoxDay} */ name="checkbox"></input>
                  <label for={d.documento.split("/").slice(-1)[0]}>{d.documento.split("/").slice(-1)[0]}</label>
                </div>
              )
            })}
          </div>

        </div>
      )

    }))
  }) : groupDaysCNC.length > 0 ? groupDaysCNC.map((element, index) => {
    return (
      <>
        <div className={ReportStyle["name-container"]}>
          <h4>{organizationNames[index]}</h4>
          <div className={ReportStyle["organization-arrow-icon"]}>
            <ion-icon name="chevron-down-outline"></ion-icon>
          </div>
        </div>
        <div className={ReportStyle["report-group-container"]}>
          {
            element.map((element) => {
              return (
                element.map((r) => {
                  return (
                    <>
                      <div className={ReportStyle["report-month-container"]}>
                        <div className={ReportStyle["layer-month-container"]}></div>
                        <input type="checkbox" id={organizationNames[index] + r.month + '' + r.year} /* onClick={toggleCheckBoxGroup} */ name="checkbox"></input>
                        <label for={organizationNames[index] + '' + r.year}> {`${monthsAvailable[r.month]} ${r.year}`} </label>
                        <div className={ReportStyle["month-arrow-icon"]}>
                          <ion-icon name="chevron-down-outline"></ion-icon>
                        </div>
                      </div>

                      <div className={ReportStyle["report-days-container"]}>
                        {r.days.map((d) => {
                          return (
                            <div className={ReportStyle["report-days-group"]}>
                              <input type="checkbox" id={d.documento.split("/").slice(-1)[0]} value={d.documento} className="input-report" /* onClick={toggleCheckBoxDay} */ name="checkbox"></input>
                              <label for={d.documento.split("/").slice(-1)[0]}>{d.documento.split("/").slice(-1)[0]}</label>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )
                })
              )
            })
          }

        </div>
      </>
    )
  }) : null

  const iconReport =
    <button title="Descargar reportes" onClick={() => showReport()} className={VisorStyle["btn-report"]}>
      <ion-icon className={VisorStyle["header-icon-report"]} name="cloud-download-outline"></ion-icon>
    </button>

  const getAccessTokenWithRefresh = async () => {
    let access = undefined;
    const res = await fetch(
      `${API_DEFAULT}/usuarios/refresh-token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'refresh': refreshToken
      })
    });

    if (!res.ok) {
      signOff();
      setShowLoader(false)
      setMessage("La sesión expiró, ingrese nuevamente")
      setSeverity("info")
      setTimeout(() => { setShowAlert(true) }, 0)
    }

    await res.json().then(res => {
      if (res['code'] === 'token_not_valid') {
        signOff();
        setShowLoader(false)
        setMessage("La sesión expiró, ingrese nuevamente")
        setSeverity("info")
        setTimeout(() => { setShowAlert(true) }, 0)
      }
      else {
        access = res['access'];
      }
    });

    return access;
  }


  // Cambia los visores disponibles dependiendo del usuario que ingresa
  useEffect(() => {
    if (dataViewers.length === 0) {
      getViewers(accessToken)
    }
  }, [accessToken]);

  // Obtiene los reportes disponibles para los clientes
  useEffect(() => {
    getReports(accessToken)
  }, [accessToken]);

  // // Obtiene las tuplas canal y programa para la vista de reach neto
  // useEffect(() => {
  //   getCanalPrograma(accessToken)
  // }, [accessToken, unificadosOptions]);

  //Cambia el visor dependiendo del path en el que se encuentre
  useEffect(() => {
    setIndex(paths.indexOf(location.pathname) - 2);
  }, [location, dataViewers]);


  // Función que obtiene el visor que se va a mostrar en la página
  const fetchPBIEmbedService = async (access = accessToken) => {
    setShowLoader(true)
    const res = await fetch(
      `${API_DEFAULT}/pbi-auth/pbi-embed-token?workspace-id=${selectedViewer.workspace_id}&report-id=${selectedViewer.report_id}`, {
      headers: {
        "Authorization": "Bearer " + access
      }
    }
    )

    res.json().then(
      async data => {
        if (data['code'] === 'token_not_valid') {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            localStorage.setItem('access', newAccess);

            fetchPBIEmbedService(newAccess);
          }
        }
        else {
          setShowLoader(false)
          setPbiEmbed(data)
          hideMenu()
        }
      }
    )
  }

  // Carga el visor del mes que se va a mostrar
  useEffect(() => {
    fetchPBIEmbedService() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataViewers, selectedViewer]);

  // Función que permite cambiar el visor que se va a mostrar
  const changeViewer = (newIndex) => {
    setIndex(newIndex);
  }

  const removeMenuItems = () => {
    let elementos = [...document.getElementsByClassName(VisorStyle["btn-month"])];

    // Recorre los elementos y verifica si contienen la palabra "Personas" en un elemento <p>
    elementos.forEach(function (elemento) {
      var parrafo = elemento.querySelector('p');
      if (parrafo) {
        if (parrafo.textContent.includes('Personas')) {
          // Si el elemento contiene la palabra "Personas" en un elemento <p>, elimínalo
          elemento.remove();
        } else if (parrafo.textContent.includes('Hogares')) {
          // Si el elemento contiene la palabra "Hogares" en un elemento <p>,
          // reemplaza el contenido del párrafo eliminando la palabra
          parrafo.textContent = parrafo.textContent.replace(' Hogares', '');
        }
      }
    });
  }

  try {
    removeMenuItems()
  } catch (e) {

  }

  // const buttons = [...document.getElementsByClassName(VisorStyle["btn-month"])];
  // buttons.forEach((button) => {
  //   button.onclick = function (e) {
  //     let x = e.clientX - e.target.offsetLeft;
  //     let y = e.clientY - e.target.offsetTop;
  //     let ripple = document.createElement("span");
  //     ripple.style.left = `${x}px`;
  //     ripple.style.top = `${y}px`;
  //     this.appendChild(ripple);
  //     setTimeout(function () {
  //       ripple.remove();
  //     }, 600);
  //   }
  // });

  // Función que muestra el menú
  const showMenu = () => {
    const modal = document.getElementById('modal-menu')
    modal.classList.remove(VisorStyle["hide-modal-menu"])
    modal.classList.toggle(VisorStyle["show-modal-menu"])

    const menu = document.getElementById('menu-container')
    menu.classList.toggle(VisorStyle["menu-transition"])
  }

  // Función que oculta el menú
  const hideMenu = () => {
    const menu = document.getElementById('menu-container')
    menu.classList.remove(VisorStyle["menu-transition"])

    const modal = document.getElementById('modal-menu')
    setTimeout(function () {
      modal.classList.replace(VisorStyle["show-modal-menu"], VisorStyle["hide-modal-menu"])
    }, 400);
  }

  // Ocultar menú con la tecla ESC
  const closeMenuWithEsc = (e) => {
    var tecla = e.keyCode;
    if (tecla === 27) {
      return hideMenu()
    }
  }
  window.onkeydown = closeMenuWithEsc;

  const showReport = () => {
    loadReports();
    const report = document.getElementById('report')
    report.classList.remove(ReportStyle["hide-report"])
    report.classList.toggle(ReportStyle["show-report"])
  }

  const hideReport = () => {
    const report = document.getElementById('report')
    report.classList.remove(ReportStyle["hide-report"])
    report.classList.toggle(ReportStyle["show-report"])
    hideReportAccordion();

    const checkboxes = [...document.getElementsByName("checkbox")]
    checkboxes.map((c) => {
      c.checked = false
    })
  }

  const hideReportAccordion = () => {
    const content = document.getElementsByClassName(ReportStyle["report-days-container"])
    for (let i = 0; i < content.length; i++) {
      content[i].classList.remove(ReportStyle['active'])
    }

    const icon = [...document.getElementsByName("chevron-down-outline")]
    icon.map((i) => {
      i.classList.remove(ReportStyle['icon-rotate'])
    })
  }

  const showScope = () => {
    const scope = document.getElementById('scope')
    scope.classList.remove(ScopeStyle["hide-scope"])
    scope.classList.toggle(ScopeStyle["show-scope"])
  }

  const showScopesStatus = () => {
    handleButtonClick()
    const scopesStatus = document.getElementById('scopes-status')
    scopesStatus.classList.remove(ScopesStatusStyle["hide-scopes-status"])
    scopesStatus.classList.toggle(ScopesStatusStyle["show-scopes-status"])
  }

  const showCertificate = () => {
    const certificate = document.getElementById('certificate')
    certificate.classList.remove(CertificateStyle["hide-certificate"])
    certificate.classList.toggle(CertificateStyle["show-certificate"])
  }

  /* let newURL = [] */
  let checked = []
  const downloadReports = () => {
    let inputs = document.getElementsByClassName("input-report")

    for (let i = 0; i < inputs.length; i++) {

      if (inputs[i].checked === true) {
        checked.push(inputs[i].value)
      }
    }
  }

  const download = url => {
    return fetch(url).then(resp => resp.blob());
  };

  const downloadByGroup = (urls, files_per_group = 5) => {
    return Promise.map(
      urls,
      async url => {
        return await download(url);
      },
      { concurrency: files_per_group }
    );
  }

  const exportZip = blobs => {
    const zip = JsZip();
    blobs.forEach((blob, i) => {
      zip.file(`${checked[i].split("/").slice(-1)[0]}`, blob);
    });
    zip.generateAsync({ type: 'blob' }).then(zipFile => {
      const fileName = `Reportes.zip`;
      return FileSaver.saveAs(zipFile, fileName);
    });
  }

  const downloadAndZip = urls => {
    setShowLoader(true)
    downloadReports();
    if (checked.length === 0) {
      setShowLoader(false)
      setMessage("Debe seleccionar al menos un reporte");
      setSeverity("danger");
      setTimeout(() => { setShowAlert(true) }, 0);
    }
    else if (checked.length === 1) {
      downloadOneFile(checked)
    } else {
      return downloadByGroup(urls, 5).then(exportZip).then(cleanURL);
    }
  }

  const downloadOneFile = file => {
    let element = document.createElement('a');
    element.setAttribute('href', file)
    element.setAttribute('download', file);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    cleanURL()
  }

  const cleanURL = () => {
    checked = []
    setShowLoader(false)
  }

  const downloadGuide = () => {
    let pdfLink = 'https://storage.googleapis.com/bucket-back-ratings/reportes/CNC/Manual_CNC_RPD_Ratings_2024_v3.pdf';

    // Realiza una solicitud fetch para descargar el archivo PDF
    fetch(pdfLink)
      .then(response => response.blob())
      .then(blob => {
        // Crea un Blob a partir de los datos descargados
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.style.display = 'none';
        a.download = 'Manual CNC RPD Ratings 2024.pdf';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error al descargar el archivo PDF:', error);
      });
  }

  return (
    <>
      <header className={VisorStyle["header-container"]}>
        <div className={VisorStyle["header-left"]}>
          <button title="Menú" onClick={() => showMenu()} className={VisorStyle["btn-menu"]}>
            <ion-icon className={VisorStyle["header-icon-menu"]} name="menu-outline" />
          </button>
          <a href="https://www.centronacionaldeconsultoria.com/" target="_blank" rel="noreferrer">
            <img className={VisorStyle["header-logo"]} src={logo} />
          </a>
          <h3 className={VisorStyle["header-title"]}></h3>
        </div>
        <div className={VisorStyle["header-right"]}>
          {showIconReport ? iconReport : null}

          <button title="Generar consulta" onClick={() => showScope()} className={VisorStyle["btn-report"]}>
            <ion-icon className={VisorStyle["header-icon-report"]} name="stats-chart-outline"></ion-icon>
          </button>

          <button title="Status de consultas" onClick={() => showScopesStatus()} className={VisorStyle["btn-report"]}>
            <ion-icon className={VisorStyle["header-icon-report"]} name="pulse-outline"></ion-icon>
          </button>

          <button title="Ver Certificado" onClick={() => showCertificate()} className={VisorStyle["btn-report"]}>
            <ion-icon className={VisorStyle["header-icon-report"]} name="reader-outline"></ion-icon>
          </button>

          <button title="Descargar Manual" onClick={() => downloadGuide()} className={VisorStyle["btn-report"]}>
            <ion-icon className={VisorStyle["header-icon-report"]} name="book-outline"></ion-icon>
          </button>

          <h3>{showUser}</h3>

          <button title="Cerrar sesión" onClick={() => logOut()} className={VisorStyle["btn-exit"]}>
            <ion-icon className={VisorStyle["header-icon-exit"]} name="exit-outline" />
          </button>
        </div>
      </header>

      <body className={VisorStyle["visor-body"]}>
        <main className={VisorStyle["visor-main"]}>

          {selectedViewer?.tipo_visor === 9 ? tabMenuTresCanales : (selectedViewer?.tipo_visor === 0 || selectedViewer?.tipo_visor === 1) ? tabMenuClaroUniverse : (selectedViewer?.tipo_visor === 3 || selectedViewer?.tipo_visor === 4) ? tabMenuModelOne : tabMenu}

          <div className={VisorStyle["main-container"]} style={{ height: (pbiEmbed['accessToken'] !== undefined && ((selectedViewer.report_id === 'b899200d-f389-40c1-aac9-3b53a3d9f9d5') || (selectedViewer.report_id === '4bc1e837-215c-4ca2-b193-17ce484d394f')) ? 575 : '') }}>
            {/* <div className={VisorStyle["main-container"]}> */}
            {
              //Aquí se carga el visor en el contenedor de la página
              pbiEmbed['accessToken'] !== undefined ?
                <PowerBIEmbed
                  embedConfig={
                    {
                      type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                      id: pbiEmbed['reportConfig'][0]['reportId'],
                      embedUrl: pbiEmbed['reportConfig'][0]['embedUrl'],
                      accessToken: pbiEmbed['accessToken'],
                      tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                      settings: {
                        panes: {
                          filters: {
                            expanded: false,
                            visible: false
                          },
                          pageNavigation: {
                            visible: (selectedViewer.report_id === 'b899200d-f389-40c1-aac9-3b53a3d9f9d5') || (selectedViewer.report_id === '4bc1e837-215c-4ca2-b193-17ce484d394f') ? true : false
                          }

                        },
                      }
                    }
                  }
                  eventHandlers={
                    new Map([
                      ['loaded', function () {
                        console.log('Report loaded');
                      }],
                      ['rendered', function () {
                        console.log('Report rendered');
                      }],
                      ['error', function (event) {
                        console.log(event.detail);
                      }]
                    ])
                  }
                  cssClassName={
                    VisorStyle["report-style-class"]
                  }
                  getEmbeddedComponent={
                    (embeddedReport) => {
                      window.report = embeddedReport;
                    }
                  }
                />
                :
                null

            }

          </div>
          <div className={VisorStyle["copyright-container"]}>
            <p className={VisorStyle["copyright-text"]}>{"Copyright © "}
              <a href="https://www.centronacionaldeconsultoria.com/" target="_blank" rel="noreferrer">
                Centro Nacional de Consultoría (CNC)
              </a> 2022
            </p>
          </div>
        </main>

        <Menu
          viewersNewUniverse={viewersNewUniverse}
          viewersModelOne={viewersModelOne}
          viewersClaroUniverse={viewersClaroUniverse}
          viewersFiveMinutes={viewersFiveMinutes}
          othersViewers={othersViewers}
          paths={paths}
          changeViewer={changeViewer}
          location={location}
          dataViewers={dataViewers}
        />

        {/* <div className={VisorStyle["modal-menu"]} id="modal-menu">
          <div className={VisorStyle["layer-hide-menu"]} onClick={() => hideMenu()}></div>
          <div className={VisorStyle["menu-container"]} id="menu-container">
            <div className={VisorStyle["menu-header"]}>
              <button title="Cerrar Menú" onClick={() => hideMenu()} className={VisorStyle["btn-menu"]}>
                <ion-icon className={VisorStyle["menu-icon"]} name="menu-outline" />
              </button>
              <a href="https://www.centronacionaldeconsultoria.com/" target="_blank">
                <img className={VisorStyle["menu-logo"]} src={logo} />
              </a>
            </div>
            <div className={VisorStyle["menu-calendar"]}>
              {months}
            </div>
          </div>
        </div> */}

        <div className={ReportStyle["report"]} id="report">
          <div className={ReportStyle["layer-hide-menu"]} onClick={() => hideReport()}></div>
          <div className={ReportStyle["report-container"]} id="report-container">
            <div className={ReportStyle["report-header"]}>
              <h2>Seleccione el reporte que desea descargar</h2>
            </div>
            <div className={ReportStyle["reports-container-scroll"]}>
              {reports.length > 0 ? reports : paintReports}
            </div>

            <div className={ReportStyle["report-button-container"]}>
              <button className={ReportStyle["report-button-cancel"]} onClick={() => hideReport()}>CANCELAR</button>
              <button id="btnDownload" className={ReportStyle["report-button-download"]} onClick={() => downloadAndZip(checked)}>DESCARGAR</button>
            </div>
          </div>
        </div>

        <Scope
          setAccessToken={setAccessToken}
          accessToken={accessToken}
          getAccessTokenWithRefresh={getAccessTokenWithRefresh}
          setShowLoader={setShowLoader}
          setShowAlert={setShowAlert}
          setMessage={setMessage}
          setSeverity={setSeverity}
        // dataCanalPrograma={dataCanalPrograma}
        // unificados={[unificadosOptions, setUnificadosOptions]}
        />

        <ScopesStatus
          setAccessToken={setAccessToken}
          accessToken={accessToken}
          getAccessTokenWithRefresh={getAccessTokenWithRefresh}
          setShowLoader={setShowLoader}
          setShowAlert={setShowAlert}
          setMessage={setMessage}
          setSeverity={setSeverity}
          ref={scopeStatusRef}
        // dataCanalPrograma={dataCanalPrograma}
        // unificados={[unificadosOptions, setUnificadosOptions]}
        />

        <Certificate />

      </body>
    </>
  );

}

export default Visor;